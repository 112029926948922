import React from 'react';
import Link from '../../../utils/Link';
import Image from '../../../utils/OptimizedImage';
import { getTag } from '../../../utils/getTag';
import EmailCaptureForm from '../../Forms/EmailCaptureForm';
import MobileForm from '../../Forms/MobileForm';
import { Copyright, Kicker, Wrapper } from '../styles/HeroContent.styles';
import HeroBody from './HeroBody';
import HeroHeading from './HeroHeading';
import HeroSearch from './HeroSearch';
// import SocialProof from './HeroSocialProof';

const HeroContent = ({
  centeredImage = false,
  colorMap,
  component,
  isFullWidth = false,
  isIntegration,
  mobileFormFlag,
}) => {
  const {
    badges,
    body,
    copyright,
    hasCycleText,
    hasSearch,
    heading,
    headingKicker,
    headingKickerTag,
    headingSize,
    hubspotFormId,
    multiColoredHeading,
    reference,
    showEmailCapture,
    socialProof,
  } = component;

  return (
    <Wrapper
      hasReference={!centeredImage && reference && reference.length > 0}
      increasedTopPadding={isIntegration}
      isFullWidth={isFullWidth}
    >
      {headingKicker && (
        <Kicker
          as={headingKickerTag ? getTag(headingKickerTag) : 'p'}
          color={colorMap?.kicker}
        >
          {headingKicker}
        </Kicker>
      )}
      {heading && (
        <HeroHeading
          heading={heading}
          hasCycleText={hasCycleText}
          headingSize={headingSize || 'Heading 1'}
          colorMap={colorMap}
        />
      )}
      {mobileFormFlag && <MobileForm formInfo={component.reference[0]} />}
      {multiColoredHeading && (
        <div className="multi-heading heading">
          <div
            dangerouslySetInnerHTML={{
              __html: multiColoredHeading,
            }}
          />
        </div>
      )}
      {body && <HeroBody component={component} colorMap={colorMap} />}
      {showEmailCapture && hubspotFormId && (
        <EmailCaptureForm formId={hubspotFormId} />
      )}
      {/* {socialProof && (
        <SocialProof
          isCentered={!(reference && reference.length > 0)}
          colorMap={colorMap}
        />
      )} */}
      {copyright && (
        <Copyright>
          <p>&copy; 2021 Shopmonkey</p>
          <Link to="/privacy-policy">
            <span>Privacy Policy</span>
          </Link>
        </Copyright>
      )}
      {badges && badges.length > 0 && (
        <div className="badges">
          {badges.map(badge => (
            <Image
              key={badge?.id}
              src={badge?.image?.url}
              alt={badge?.image?.alt || 'G2 Badge Image'}
              className="hero-badge"
            />
          ))}
        </div>
      )}
      {hasSearch && <HeroSearch />}
    </Wrapper>
  );
};

export default HeroContent;
